<!--
 * @Author: lbh
 * @Date: 2021-04-28 18:49:27
 * @LastEditors: lbh
 * @LastEditTime: 2021-04-29 12:00:20
 * @Description: 黄珍珍泰國菜館
-->
<template>
  <div
    class="gsq-box t-body"
    :style="`min-height:${window.bodyH}px;`"
  >
    <selfHeaderN
      ad="case"
      child='daxiwu'
      :type="window.type"
    />
    <div
      class="s-body"
      :class="window.type=='1000' ? '' : 's-body_1'"
    >
      <selfBackground
        :backH="window.backH"
        title="熱門案例"
        :type="1"
        :wtype="window.type"
        :image="getImgPath('case.jpg')"
      />
      <selfGraphic
        :type="window.type"
        :imgs="graphic.imgs"
        :datas="graphic.datas"
        :height="graphic.height"
        title="黄珍珍泰國菜館"
        :bImg="graphic.bImg"
        bImgP="left"
      />
    </div>
    <selfFooter :type="window.type" />
  </div>
</template>
<script>
import selfHeaderN from "@/components/header/headerN";
import selfFooter from "@/components/footer/footer";
import selfBackground from "@/components/commo/background";
import selfGraphic from "@/components/commo/graphic";
import util from "@/js/utils/util";
export default {
  components: {
    selfHeaderN,
    selfFooter,
    selfBackground,
    selfGraphic
  },
  data () {
    return {
      window: {
        type: '1000',
        bodyH: 0,
        backH: 0,
      },
      graphic: {
        imgs: [],
        datas: [],
        height: 0,
        bImg: ''
      },
      bgImage: ''
    }
  },
  mounted () {
    util.setTitle('黄珍珍泰國菜館 – 薑軟件有限公司');
    // 初始化窗口
    this.initWindow();
    // 監聽 窗口
    window.onresize = () => {
      // 1000 700
      this.initWindow();
    };
    // 初始化數據
    let imgs = [
      util.getImgPath('WCCFood_Shot01-500x400.jpg'),
      util.getImgPath('WCCFood_Shot02-500x400.jpg'),
      util.getImgPath('WCC_shop-500x403.jpg')];
    this.graphic.imgs = imgs;
    let datas = [
      {
        title: "餐廳面對問題",
        items: [
          { title: '- 沒有自己的會員系統， 不能直接給顧客送贈禮物及優惠券', style: 'margin-top:25px' },
          { title: '- 泰國廚師經常落錯單' },
          { title: '', style: 'height:1px;background-color:#000;margin-top:35px;' }
        ]
      },
      {
        title: '解決方案', items: [
          { title: '產品名稱: POS + Mobile POS', style: 'margin-top:25px' },
          { title: '項目年份: 2018' },
          { title: '- 店員用Mobile POS落單, 節省員工落單時間, 減低出錯' },
          { title: '- 廚房同時打印泰文及中文, 方便泰國廚師及樓面人員溝通' },
        ],
      },
    ];
    this.graphic.datas = datas;
    this.graphic.bImg = util.getImgPath('WCC_case.png');
  },
  methods: {
    initWindow () {
      let W = util.W(), w = W.w, h = W.h;
      this.window.bodyH = h;
      this.window.backH = parseInt(w / 5);
      if (w < 700) {
        this.window.type = '600';
        this.window.backH = w / 2.5;
        this.graphic.height = (w * 0.8 - 40) * 0.8;
      } else if (w < 1000) {
        this.window.type = '900';
        this.window.backH = w / 2.5;
        this.graphic.height = (w * 0.8 - 40) * 0.8;
      } else {
        this.window.type = '1000';
        this.graphic.height = (w * 0.8 / 2 - 40) * 0.8;
      }
    },
    getImgPath (t) {
      return util.getImgPath(t);
    }
  }
}
</script>
<style lang="less" scoped>
</style>
